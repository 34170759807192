import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClassifiedsRoutingModule } from './classifieds-routing.module';
import {ClassifiedsComponent} from "./classifieds.component";
import {SharedComponentsModule} from "../../@components/shared-components.module";


@NgModule({
  declarations: [
    ClassifiedsComponent,
  ],
  imports: [
    CommonModule,
    ClassifiedsRoutingModule,
    SharedComponentsModule
  ]
})
export class ClassifiedsModule { }
