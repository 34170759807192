import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {ProductCard} from "../../../@models/product.model";

@Component({
  selector: 'app-featured-view',
  templateUrl: './featured-view.component.html',
  styleUrls: ['./featured-view.component.scss'],
})
export class FeaturedViewComponent implements OnInit, AfterViewInit {
  @ViewChild('thumbs', {static: true})
  thumbs: ElementRef;
  @Input()
  previewItem: ProductCard;
  @Output()
  closeQuickView: EventEmitter<any> = new EventEmitter();

  galleryConfig: SwiperConfigInterface;

  constructor() {
  }

  ngOnInit() {
    this.galleryConfig = {
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 650,
      allowTouchMove: false,
      thumbs: {
        swiper: {
          el: this.thumbs.nativeElement,
          slidesPerView: 4,
          direction: 'vertical',
          spaceBetween: 15
        }
      }
    };
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.galleryConfig.thumbs = {
    //     swiper: {
    //       el: this.thumbs.nativeElement,
    //       slidesPerView: 4,
    //       direction: 'vertical',
    //       spaceBetween: 15
    //     }
    //   };
    // }, 0);
  }

  onCloseQuickView() {
    this.closeQuickView.emit();
  }
}
