import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  @Input() searchType: string;

  searchIn: string[];
  categories: string[];
  groups: string[];
  distance: string[];
  searchForm: FormGroup;
  formType: string;

  constructor(
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.formType = this.searchType === 'stores' ? 'stores' : 'classifieds';

    if (this.formType === 'stores') {
      this.searchIn = ['Stores', 'Items'];
      this.categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4'];
      this.groups = ['Group 1', 'Group 2', 'Group 3', 'Group 4'];
      this.distance = ['+1 km', '+2 km', '+3 km', '+4 km'];
    } else {
      this.searchIn = ['Offered', 'Wanted'];
      this.categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4'];
      this.groups = ['Group 1', 'Group 2', 'Group 3', 'Group 4'];
      this.distance = ['+1 km', '+2 km', '+3 km', '+4 km'];
    }

    this.searchForm = this.fb.group({
      searchIn: [this.searchIn[0]],
      categories: [null],
      groups: [null],
      distance: [this.distance[0]]
    });
  }
}
