import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit, QueryList, TemplateRef,
  ViewChild, ViewChildren,
  ViewContainerRef, ViewRef
} from '@angular/core';
import {ProductCard} from "../../@models/product.model";
import {SwiperConfigInterface, SwiperPaginationInterface} from "ngx-swiper-wrapper";
import {FeaturedViewComponent} from "./featured-view/featured-view.component";
import {FeaturedItemComponent} from "./featured-item/featured-item.component";

@Component({
  selector: 'app-featured-listings',
  templateUrl: './featured-listings.component.html',
  styleUrls: ['./featured-listings.component.scss'],
})
export class FeaturedListingsComponent implements OnInit, AfterViewInit {
  previewItem: ProductCard = null;
  @ViewChild('featuredView', {static: false})
  featuredView: ElementRef;
  @ViewChild('bullets', {static: false})
  bullets: ElementRef;
  @Input()
  enableBigItem = true;
  @Input()
  featuredProducts: ProductCard[];
  restructuredProducts: any = [];
  clickedBlock;
  swiperConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    speed: 650,
    spaceBetween: 40
  };

  constructor() {
  }

  ngOnInit() {
    if (this.enableBigItem) {
      const numberOfItems = 5;
      const length = Math.floor(this.featuredProducts.length / numberOfItems);

      for (let i = 0; i <= length; i++) {
        const multiplier = i * numberOfItems;
        const products = this.featuredProducts.slice(multiplier, multiplier + numberOfItems);
        const result = {
          bigItem: products[0],
          items: products.slice(1, numberOfItems)
        };
        this.restructuredProducts.push(result);
      }
    } else {
      const numberOfItems = 12;
      const length = Math.floor(this.featuredProducts.length / numberOfItems);

      for (let i = 0; i <= length; i++) {
        const multiplier = i * numberOfItems;
        const products = this.featuredProducts.slice(multiplier, multiplier + numberOfItems);

        this.restructuredProducts.push(products);
      }
    }
  }

  onShowQuickView(event) {
    this.previewItem = event.item;
    this.clickedBlock = event.clickedBlock;

    setTimeout(() => {
      this.featuredView.nativeElement.scrollIntoView({
        behavior: 'smooth'
      });
    }, 0);
  }

  onCloseQuickView() {
    this.previewItem = null;

    this.clickedBlock.scrollIntoView({
      behavior: 'smooth'
    });
  }

  ngAfterViewInit(): void {
    this.swiperConfig.pagination = {
      el: this.bullets.nativeElement,
      type: 'bullets',
      clickable: true
    };
  }
}
