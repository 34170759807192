import { Component, OnInit } from '@angular/core';
import {ProductCard} from "../../@models/product.model";
import {Store} from "../../@models/store.model";

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  specialsItems: ProductCard[] = [
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
  ];
  newProducts: ProductCard[] = [
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
  ];
  newStores: Store[] = [
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
    new Store(
      0,
      'Store name',
      'Category name',
      'assets/images/store-card-placeholder.jpg',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit int'
    ),
  ];
  constructor() { }

  ngOnInit() {
  }

}
