import {Component, OnInit} from '@angular/core';
import {ProductCard} from "../../@models/product.model";

@Component({
  selector: 'app-recently-viewed-items',
  templateUrl: './recently-viewed-items.component.html',
  styleUrls: ['./recently-viewed-items.component.scss']
})
export class RecentlyViewedItemsComponent implements OnInit {
  recentlyViewedItems: ProductCard[] = [
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
    new ProductCard(
      0,
      'assets/images/recently-view-item.jpg',
      [],
      'Item name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      205,
      190),
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
