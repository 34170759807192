import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoresRoutingModule } from './stores-routing.module';
import {StoresComponent} from "./stores.component";
import {SharedComponentsModule} from "../../@components/shared-components.module";


@NgModule({
  declarations: [
    StoresComponent,
  ],
  imports: [
    CommonModule,
    StoresRoutingModule,
    SharedComponentsModule,
  ]
})
export class StoresModule { }
