import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecentlyViewedItemsComponent} from "./recently-viewed-items/recently-viewed-items.component";
import {RecentlyViewItemComponent} from './recently-viewed-items/recently-view-item/recently-view-item.component';
import {ProductCardsComponent} from './product-cards/product-cards.component';
import {ProductCardComponent} from './product-cards/product-card/product-card.component';
import {SwiperModule} from "ngx-swiper-wrapper";
import {StoresCardsComponent} from './stores-cards/stores-cards.component';
import {StoresCardComponent} from './stores-cards/stores-card/stores-card.component';
import {FeaturedListingsComponent} from './featured-listings/featured-listings.component';
import {FeaturedItemComponent} from './featured-listings/featured-item/featured-item.component';
import { FeaturedViewComponent } from './featured-listings/featured-view/featured-view.component';
import {MatDialogModule, MatDialogRef} from "@angular/material";
import { ProductQuickViewComponent } from './product-cards/product-quick-view/product-quick-view.component';
import { StoreQuickViewComponent } from './stores-cards/store-quick-view/store-quick-view.component';


@NgModule({
  declarations: [
    RecentlyViewedItemsComponent,
    RecentlyViewItemComponent,
    ProductCardsComponent,
    ProductCardComponent,
    StoresCardsComponent,
    StoresCardComponent,
    FeaturedListingsComponent,
    FeaturedItemComponent,
    FeaturedViewComponent,
    ProductQuickViewComponent,
    StoreQuickViewComponent,
  ],
  exports: [
    RecentlyViewedItemsComponent,
    RecentlyViewItemComponent,
    ProductCardsComponent,
    ProductCardComponent,
    StoresCardsComponent,
    StoresCardComponent,
    FeaturedListingsComponent,
    FeaturedItemComponent,
    FeaturedViewComponent,
    ProductQuickViewComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    MatDialogModule
  ],
})
export class SharedComponentsModule {
}
