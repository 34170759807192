import {Component, Input, OnInit} from '@angular/core';
import {ProductCard} from "../../../@models/product.model";

@Component({
  selector: 'app-recently-view-item',
  templateUrl: './recently-view-item.component.html',
  styleUrls: ['./recently-view-item.component.scss']
})
export class RecentlyViewItemComponent implements OnInit {
  @Input() item: ProductCard;
  constructor() { }

  ngOnInit() {
  }

}
