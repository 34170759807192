import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {SwiperThumbsInterface} from "ngx-swiper-wrapper/dist/lib/swiper.interfaces";
import {ProductCard} from "../../../@models/product.model";

@Component({
  selector: 'app-featured-item',
  templateUrl: './featured-item.component.html',
  styleUrls: ['./featured-item.component.scss']
})
export class FeaturedItemComponent implements OnInit, AfterViewInit {
  galleryConfig: SwiperConfigInterface;
  @ViewChild('thumbs', {static: true})
  thumbs: ElementRef;
  @Input()
  size: string;
  @Output()
  showQuickView: EventEmitter<any> = new EventEmitter();
  @Input()
  item: ProductCard;

  constructor() {
  }

  ngOnInit() {

    this.galleryConfig = {
      slidesPerView: 1,
      speed: 650,
      spaceBetween: 40,
      allowTouchMove: false,
      thumbs: {
        swiper: {
          el: this.thumbs.nativeElement,
          slidesPerView: this.size === 'sm' ? 3 : 4,
          direction: 'vertical',
          spaceBetween: this.size === 'sm' ? 5 : 15,
        }
      }
    };
  }

  ngAfterViewInit(): void {
  }

  isSmallSize() {
    return this.size === 'sm';
  }

  getPriceClasses() {
    return {'text--size-30': !this.isSmallSize(), 'text--size-20': this.isSmallSize()};
  }

  getCategoryClasses() {
    return {'text--size-14': !this.isSmallSize(), 'text--size-12': this.isSmallSize()};
  }

  getNameClasses() {
    return {'text--size-20': !this.isSmallSize(), 'text--size-18': this.isSmallSize()};
  }

  onQuickView(blockElem) {
    this.showQuickView.emit({item: this.item, clickedBlock: blockElem});
  }
}
