import { Component, OnInit } from '@angular/core';
import {ProductCard} from "../../@models/product.model";

@Component({
  selector: 'app-classifieds',
  templateUrl: './classifieds.component.html',
  styleUrls: ['./classifieds.component.scss']
})
export class ClassifiedsComponent implements OnInit {
  classifieds: ProductCard[] = [
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
    new ProductCard(
      0,
      'assets/images/specials-product.jpg',
      [
        'assets/images/feature-item-pic1.jpg',
        'assets/images/feature-item-pic2.jpg',
        'assets/images/feature-item-pic3.jpg',
        'assets/images/feature-item-pic4.jpg',
        'assets/images/feature-item-pic5.jpg',
      ],
      'Item Name',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedola',
      330,
      205),
  ];
  constructor() { }

  ngOnInit() {
  }

}
