import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeroComponent} from './@components/hero/hero.component';
import {HeaderComponent} from './@components/hero/header/header.component';
import {SearchBlockComponent} from './@components/hero/search-block/search-block.component';
import {FooterComponent} from './@components/footer/footer.component';
import {SearchFormComponent} from './@components/hero/search-block/search-form/search-form.component';
import {StoresModule} from "./@pages/stores/stores.module";
import {ClassifiedsModule} from "./@pages/classifieds/classifieds.module";


import {NgSelectModule} from "@ng-select/ng-select";

// Material
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatDialogModule, MatTabsModule} from "@angular/material";
import {SwiperModule} from "ngx-swiper-wrapper";
import {ProductQuickViewComponent} from "./@components/product-cards/product-quick-view/product-quick-view.component";
import {StoreQuickViewComponent} from "./@components/stores-cards/store-quick-view/store-quick-view.component";



@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    HeaderComponent,
    SearchBlockComponent,
    FooterComponent,
    SearchFormComponent,
  ],
  imports: [
    BrowserModule,
    StoresModule,
    ClassifiedsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatDialogModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    SwiperModule,
  ],
  providers: [],
  entryComponents: [
    ProductQuickViewComponent,
    StoreQuickViewComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
