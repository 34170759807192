import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-search-block',
  templateUrl: './search-block.component.html',
  styleUrls: ['./search-block.component.scss']
})
export class SearchBlockComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onTabChange(event) {
    const routeLink = event.tab.textLabel.toLowerCase() === 'classifieds' ? 'classifieds' : 'stores';

    this.router.navigate([routeLink]);
  }
}
