import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Store} from "../../../@models/store.model";
import {MAT_DIALOG_DATA} from "@angular/material";

@Component({
  selector: 'app-store-quick-view',
  templateUrl: './store-quick-view.component.html',
  styleUrls: ['./store-quick-view.component.scss']
})
export class StoreQuickViewComponent implements OnInit {
  @Input()
  store: Store;
  @Output()
  closePreview: EventEmitter<any> = new EventEmitter();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
  }

  onClosePreview() {
    this.closePreview.emit();
  }
}
