import {Component, Inject, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {ProductCard} from "../../@models/product.model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";
import {ProductQuickViewComponent} from "./product-quick-view/product-quick-view.component";

@Component({
  selector: 'app-product-cards',
  templateUrl: './product-cards.component.html',
  styleUrls: ['./product-cards.component.scss']
})
export class ProductCardsComponent implements OnInit {
  @Input()
  productType = 'newProduct';
  @Input()
  labelString = '';
  @Input()
  products: ProductCard[];
  @Input()
  flatGrid = false;
  viewProduct: ProductCard;
  swiperConfig: SwiperConfigInterface = {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 70,
    speed: 750,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
  };
  constructor(
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.viewProduct = this.products[0];
  }

  onOpenQuickView(product) {
    this.dialog.open(ProductQuickViewComponent, {
      panelClass: 'quick-view-dialog',
      width: 'auto',
      maxWidth: 'auto',
      data: {
        product,
        productType: this.productType,
        labelString: this.labelString
      }
    });
  }
}
