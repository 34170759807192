import {Component, Input, OnInit} from '@angular/core';
import {SwiperConfigInterface} from "ngx-swiper-wrapper";
import {Store} from "../../@models/store.model";
import {MatDialog} from "@angular/material";
import {ProductQuickViewComponent} from "../product-cards/product-quick-view/product-quick-view.component";
import {StoreQuickViewComponent} from "./store-quick-view/store-quick-view.component";

@Component({
  selector: 'app-stores-cards',
  templateUrl: './stores-cards.component.html',
  styleUrls: ['./stores-cards.component.scss']
})
export class StoresCardsComponent implements OnInit {
  swiperConfig: SwiperConfigInterface = {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    speed: 750,
  };
  @Input()
  stores: Store[] = [];
  previewStore: Store = null;
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  onShowPreview(store) {
    this.dialog.open(StoreQuickViewComponent, {
      panelClass: 'quick-view-dialog',
      width: 'auto',
      maxWidth: 'auto',
      data: {
        store,
      }
    });
  }

  onClosePreview() {
    this.previewStore = null;
  }
}
