import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from "../../../@models/store.model";

@Component({
  selector: 'app-stores-card',
  templateUrl: './stores-card.component.html',
  styleUrls: ['./stores-card.component.scss']
})
export class StoresCardComponent implements OnInit {
  @Input()
  store: Store;
  @Output()
  showPreview: EventEmitter<Store> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onShowPreview() {
    this.showPreview.emit(this.store);
  }
}
