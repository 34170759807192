export class ProductCard {
  constructor(
    public id: number,
    public imgPath: string = '',
    public images: string[] = [],
    public name: string = '',
    public description: string = '',
    public price: number = 0,
    public discountPrice: number = 0,
  ) {}
}
