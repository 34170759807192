import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ProductCard} from "../../../@models/product.model";
import {SwiperConfigInterface, SwiperDirective} from "ngx-swiper-wrapper";
import {MAT_DIALOG_DATA} from "@angular/material";

@Component({
  selector: 'app-product-quick-view',
  templateUrl: './product-quick-view.component.html',
  styleUrls: ['./product-quick-view.component.scss']
})
export class ProductQuickViewComponent implements OnInit {
  @ViewChild('thumbs', {static: true})
  thumbs: ElementRef;
  @ViewChild('nextPhoto', {static: true})
  nextPhoto: ElementRef;
  @ViewChild('prevPhoto', {static: true})
  prevPhoto: ElementRef;
  swiperConfig: SwiperConfigInterface;
  @Input()
  productType = '';
  @Input()
  labelString = '';
  @Input()
  product: ProductCard;
  @Output()
  closeQuickView: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.swiperConfig = {
      slidesPerView: 1,
      speed: 650,
      spaceBetween: 40,
      allowTouchMove: false,
      navigation: {
        nextEl: this.nextPhoto.nativeElement,
        prevEl: this.prevPhoto.nativeElement
      },
      thumbs: {
        swiper: {
          el: this.thumbs.nativeElement,
          slidesPerView: 4,
          speed: 650,
          direction: 'vertical',
          spaceBetween: 5
        }
      }
    };
  }

  onClose() {
    this.closeQuickView.emit();
  }
}
