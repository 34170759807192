import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductCard} from "../../../@models/product.model";
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input()
  type: string;
  @Input()
  labelString: string;
  @Input()
  product: ProductCard;
  @Output()
  openQuickView: EventEmitter<ProductCard> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

  }

  onOpenView() {
    this.openQuickView.emit(this.product);
  }
}
